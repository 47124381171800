import { Base64 } from "js-base64";

export function base64ToBlob(b64Data, contentType = "", sliceSize = 512) {
  const byteCharacters = Base64.atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function convertToB64Str(string) {
  const base64Str = Base64.btoa(string);
  return base64Str;
}

export function decodeB64(string) {
  const decodeStr = Base64.atob(string);
  return decodeStr;
}

export function convertBase64File(newFiles) {
  const promise = new Promise((resolve, reject) => {
    const allFiles = [];
    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64result = reader.result.substr(
          reader.result.indexOf(",") + 1
        );
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: base64result,
        };
        allFiles.push(fileInfo);
        if (newFiles.length === allFiles.length) {
          resolve(allFiles);
        } else {
          reject(Error("Error"));
        }
      };
    });
  });

  return promise;
}
